// import '@khanacademy/tota11y/dist/tota11y.min.js';
import '../scss/main.scss'
import slideshow from './slideshow'
import {
  animateMenu,
  scrollHome,
  scrollCards,
  scrollGallery,
} from './animations'
import { insertGallery, imgModal } from './gallery'
import askConsent from './consent'

window.onload = () => {
  const nav = document.querySelector('.j-nav')
  const slideShow = document.querySelector('.j-slideshow')
  const home = document.querySelector('.j-arrow')
  const card = document.querySelector('.j-card')
  const gallery = document.querySelector('.j-gallery')
  const consent = document.querySelector('.j-consent')

  if (nav) {
    animateMenu(nav)
  }

  if (slideShow) {
    slideshow()
  }

  if (home) {
    scrollHome()
  }

  if (card) {
    scrollCards()
  }

  if (gallery) {
    insertGallery()
    scrollGallery()
    imgModal()
  }

  if (consent) {
    askConsent()
  }
}
