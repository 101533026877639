export default () => {
  const cookieStorage = {
    getItem: (key) => {
      const cookies = document.cookie
        .split(';')
        .map((cookie) => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[key];
    },
    setItem: (key, value) => {
      document.cookie = `${key}=${value}`;
    },
  };

  const storageType = cookieStorage;
  const consentPropertyName = 'srtendaggi_consent';

  const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
  const saveToStorage = () => storageType.setItem(consentPropertyName, true);

  const consentPopup = document.querySelector('.j-consent');
  const acceptBtn = document.querySelector('.j-consent__accept');
  const closeBtn = document.querySelector('.j-consent__close');

  const acceptFn = (event) => {
    event.preventDefault();
    saveToStorage(storageType);
    consentPopup.classList.add('c-consent--hidden');
  };

  acceptBtn.addEventListener('click', acceptFn);
  closeBtn.addEventListener('click', acceptFn);

  if (shouldShowPopup(storageType)) {
    setTimeout(() => {
      consentPopup.classList.remove('c-consent--hidden');
    }, 1200);
  }
};
