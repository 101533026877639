export const animateMenu = (nav) => {
  const hoverArea = nav.lastElementChild;
  const btn = hoverArea.firstElementChild;
  const items = document.querySelectorAll('.j-nav__item');
  const close = document.querySelector('.j-nav__close');

  // Main menu
  const tl = gsap.timeline({
    defaults: { duration: 1.5, ease: 'power3.out' },
    paused: true,
  });

  tl.to(nav, { x: '0%' });
  tl.to(btn, { opacity: 0 }, '-=1.5');
  tl.from(
    items,
    {
      x: '-20rem',
      opacity: 0,
      stagger: 0.2,
    },
    '-=1.5'
  );

  nav.addEventListener('mouseenter', () => {
    tl.timeScale(1).play();
  });
  nav.addEventListener('mouseleave', () => {
    tl.timeScale(1.7).reverse();
  });

  btn.addEventListener('click', () => {
    tl.timeScale(1).play();
  });
  close.addEventListener('click', () => {
    tl.timeScale(1.7).reverse();
  });

  // Submenu
  for (let i = 0; i < items.length; i += 1) {
    items[i].addEventListener('click', function (e) {
      // Remove default if element has submenu
      if (items[i].childElementCount > 1) {
        e.preventDefault();
        // Reinstate default to sublinks
        items[i].childNodes[1].addEventListener('click', (ev) =>
          ev.stopPropagation()
        );
      }

      const panel = this.lastChild;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = `${panel.scrollHeight}px`;
      }
    });
  }
};

export const scrollHome = () => {
  gsap.to('.header__img', {
    scrollTrigger: {
      trigger: '.section-presentation',
      end: 'top top',
      scrub: 1,
    },
    opacity: 0,
  });

  gsap.to('.c-scroll-arrow', {
    scrollTrigger: {
      trigger: '.section-presentation',
      end: 'top center',
      scrub: 1,
    },
    opacity: 0,
  });

  // Text presentation
  gsap.set('#text-presentation', { opacity: 0, x: 100 });
  gsap.to('#text-presentation', {
    scrollTrigger: {
      trigger: '#text-presentation',
      start: 'top 80%',
    },
    opacity: 1,
    x: 0,
    duration: 1.5,
  });

  // Testimoniaals cards
  gsap.set('.c-testimonials__card', { opacity: 0, x: 100 });
  gsap.to('.c-testimonials__card', {
    scrollTrigger: {
      trigger: '.c-testimonials__card',
      start: 'top 80%',
    },
    stagger: 0.5,
    opacity: 1,
    x: 0,
    duration: 1.5,
  });
};

export const scrollCards = () => {
  gsap.set('.c-card', { opacity: 0, x: 500 });

  ScrollTrigger.batch('.c-card', {
    start: 'top center',
    onEnter: (batch) => {
      gsap.to(batch, {
        duration: 1.5,
        opacity: 1,
        x: 0,
        ease: 'power3.out',
      });
    },
  });
};

export const scrollGallery = () => {
  gsap.set('.j-gallery__fig', { opacity: 0, y: 50, rotateY: 90 });

  ScrollTrigger.batch('.j-gallery__fig', {
    start: 'top 80%',
    onEnter: (batch) => {
      gsap.to(batch, {
        opacity: 1,
        y: 0,
        rotateY: 1,
        ease: 'Power3.easeOut',
        duration: 2,
        stagger: 0.2,
      });
    },
  });
};
