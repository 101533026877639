function importAll(r) {
  const images = [];
  r.keys().forEach((item) => {
    images.push(item.replace('./', ''));
  });
  return images;
}

// Create Context for Webpack
const imagesClassiche = importAll(
  require.context('../img/gallery/tende-classiche', false, /.*\.jpeg$/)
);
const imagesTecniche = importAll(
  require.context('../img/gallery/tende-tecniche', false, /.*\.jpeg$/)
);
const imagesRullo = importAll(
  require.context('../img/gallery/tende-a-rullo', false, /.*\.jpeg$/)
);
const imagesTappezzeria = importAll(
  require.context('../img/gallery/tappezzeria', false, /.*\.jpeg$/)
);

function getGalleryArray(name) {
  const nameLow = name.toLowerCase();
  switch (nameLow) {
    case 'tende classiche':
      return imagesClassiche;
    case 'tende tecniche':
      return imagesTecniche;
    case 'tappezzeria':
      return imagesTappezzeria;
    case 'tende a rullo':
      return imagesRullo;
    default:
      return null;
  }
}

// Insert into DOM
export function insertGallery() {
  // Get Gallery
  const products = document.querySelector('.section-products');
  const productsTitle = products.firstChild.textContent;
  // Define which array of images to load
  const galleryArray = getGalleryArray(productsTitle);
  const gallery = document.querySelector('.j-gallery');
  // Create elements and insert them into the DOM
  for (let i = 0; i < galleryArray.length; i += 1) {
    // Figure
    const figure = document.createElement('figure');
    figure.classList.add('gallery__item', 'j-gallery__fig');
    // Img
    const img = document.createElement('img');
    img.classList.add('gallery__img');
    img.src = `./img/${galleryArray[i]}`;
    img.alt = `${productsTitle} ${i + 1}`;
    // Item number
    const p = document.createElement('p');
    p.innerHTML = i + 1;
    p.classList.add('gallery__number');
    // Put all together
    figure.append(p, img);
    // Inset all inside the gallery
    gallery.append(figure);
  }
}

export function imgModal() {
  // Get the modal parts
  const modal = document.querySelector('.j-modal');
  const modalImg = document.querySelector('.j-modal__img');
  const captionText = document.querySelector('.j-modal__caption');
  // Get the images
  const figs = document.querySelectorAll('.j-gallery__fig');
  // Get the arrows
  const arrowRight = document.querySelector('#j-rightArrow');
  const arrowLeft = document.querySelector('#j-leftArrow');
  // Set active modal index
  let active = null;

  // Hide nav if modal is active
  // if (modal.style.display === 'flex')
  // Insert images inside the modal when clicked - use its "alt" text as a caption
  figs.forEach((fig, i) => {
    fig.onclick = function () {
      const img = this.childNodes[1];
      modal.style.display = 'flex';
      modalImg.src = img.src;
      captionText.innerHTML = img.alt;
      active = i;
      // Hide nav when modal is active
      document.querySelector('.j-nav').style.display = 'none';
    };
  });

  arrowRight.onclick = function () {
    // Find next index
    const nextFig = figs[active + 1];
    // If none list is ended
    if (!nextFig) return;
    // Select child image
    const nextImg = nextFig.childNodes[1];
    // Insert new image in the modal
    modalImg.src = nextImg.src;
    captionText.innerHTML = nextImg.alt;
    // Update index of opened image
    active += 1;
  };

  arrowLeft.onclick = function () {
    // Find prev index
    const prevFig = figs[active - 1];
    // If none list is ended
    if (!prevFig) return;
    // Select child image
    const prevImg = prevFig.childNodes[1];
    // Insert new image in the modal
    modalImg.src = prevImg.src;
    captionText.innerHTML = prevImg.alt;
    // Update index of opened image
    active -= 1;
  };

  // Get the <span> element that closes the modal
  const span = document.getElementsByClassName('j-modal__close')[0];

  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
    modal.style.display = 'none';
    // Show nav when modal is closed
    document.querySelector('.j-nav').style.display = 'flex';
  };
}
