export default function () {
  const images = document.querySelectorAll('.j-slideshow__img');
  const groups = document.querySelectorAll('.j-slideshow__group');
  const titles = document.querySelectorAll('.j-slideshow__heading');

  const slidesNumber = images.length / groups.length;
  const dur = 1.5;
  let oldSlide = 0;
  let activeSlide = 0;

  function moveRight(n) {
    for (let i = 0; i < images.length; i += slidesNumber) {
      gsap.to(images[i + n], dur, { x: '0', ease: 'power3.out' });
      gsap.from(images[i + n], dur, {
        opacity: '0',
        clearProps: 'opacity',
        ease: 'power3.out',
      });
    }
  }

  function moveLeft(n) {
    for (let i = 0; i < images.length; i += slidesNumber) {
      gsap.to(images[n + i + 1], 1, { x: '101%', ease: 'power2.in' });
      gsap.to(images[n + i + 1], 1, {
        opacity: '0',
        clearProps: 'opacity',
        ease: 'power2.in',
      });
    }
  }

  function setTitle(n) {
    gsap.set(titles, { display: 'none' });
    gsap.set(titles[n], { display: 'block' });
  }

  function slideAnim() {
    oldSlide = activeSlide;

    // arrow clicks
    if (this.id === 'j-leftArrow' || this.id === 'j-rightArrow') {
      activeSlide =
        this.id === 'j-rightArrow' ? (activeSlide += 1) : (activeSlide -= 1);
    }
    // make sure we're not past the end or beginning slide
    activeSlide = activeSlide < 0 ? 0 : activeSlide;
    activeSlide =
      activeSlide > slidesNumber - 1 ? slidesNumber - 1 : activeSlide;

    if (oldSlide === activeSlide) {
      return;
    }
    // animate and set titles
    if (this.id === 'j-rightArrow') {
      setTitle(activeSlide);
      moveRight(activeSlide);
    }

    if (this.id === 'j-leftArrow') {
      setTitle(activeSlide);
      moveLeft(activeSlide);
    }
  }

  document.getElementById('j-leftArrow').addEventListener('click', slideAnim);
  document.getElementById('j-rightArrow').addEventListener('click', slideAnim);

  moveRight(activeSlide);
  setTitle(activeSlide);
}
